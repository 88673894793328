.pc-mp-body--build-body {
  min-height: calc(100vh - 45px);

  .pc-mp-build-section {
    margin: 0 10px;

    .pc-mp-build-squares-container {
      display: flex;

      .pc-mp-build-square-container {
        background: $white;
        padding: 50px;
        text-align: center;
        -moz-box-shadow:rgba(0,0,0,0.125) 0 1px 3px;
        -webkit-box-shadow:rgba(0,0,0,0.125) 0 1px 3px;
        box-shadow:rgba(0,0,0,0.125) 0 1px 3px;

        h3 {
          font-size: 24px;
          margin-top: 0;
        }

        img {
          height: 100px;
        }

        p {
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
  }
}

@media (min-width: 1250px) {
  .pc-mp-body--build-body {
    padding: 50px 25px;
  }
}

@media (max-width: 1250px) {
  .pc-mp-body--build-body {
    padding: 25px;
  }
}

@media (min-width: 769px) {
  .pc-mp-build-squares-container {
    .pc-mp-build-square-container {
      width: 50%;
    }
    .pc-mp-build-square-container:first-of-type {
      margin-right: 15px;
    }
  }
}

@media (max-width: 769px) {
  .pc-mp-build-squares-container {
    flex-wrap: wrap;
    .pc-mp-build-square-container {
      width: 100%;
    }
    .pc-mp-build-square-container:first-of-type {
      margin-bottom: 15px;
    }
  }
}
