.search-component__wrapper {
  position: relative;
  margin: 10px 0;
  padding: 0 10px;
}

.search-component__input {
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0 25px 0 5px;
}

.search-component--button__clear {
  height: 13px;
  width: 13px;
  border: none;
  outline: none;
  position: absolute;
  right: 15px;
  top: 50%;
  padding: 0;
  background-color: transparent;
  transform: translateY(-50%);

  &:after, &:before {
    content: '';
    display: block;
    height: 100%;
    width: 3px;
    position: absolute;
    top: 0;
    background-color: $lightgray;
    transition: 0.3s background-color;
  }

  &:after {
    transform: rotate(45deg);
    left: 5px;
  }

  &:before {
    transform: rotate(-45deg);
    right: 5px;
  }

  &:hover {
    &:after, &:before {
      background-color: $gray;
    }
  }
}
