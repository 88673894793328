@use "sass:color";

// Global Styles //
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background-color: $background-color;
}

.alert-notice {
  width: 960px;
  padding: 12px;
  font-size: 16px;
  background-color: #fff;
  margin: 12px auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  border-left: 5px solid $notice;

  .fa {
    color: $notice;
    margin-right: 8px;
    line-height: 18px;
  }
}

.alert-success {
  text-align: center;
  position: absolute;
  margin-top: 5px;
  font-size: 15px;
  display: inline-block;
  background: white;
  padding: 5px;
  left: 5px;
  z-index: 1000;
}

.hidden {
  display: none;
}

.account-nav {
  height: 20px;
  background-color: #000;

  .content {
    width: $site-content-width;
    margin: auto;
    text-align: right;
  }

  a {
    color: #aaa;

    &:hover {
      color: color.adjust($procore, $lightness: -10%);
      text-decoration: underline;
    }
  }
}

.main-navigation {
  background-color: #fff;
  height: 70px;
 }

.blocker {
  z-index: 1000000 !important;
}

.no-scroll {
  position: fixed;
}

#overlay {
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  z-index: 5000;
}

// Header Styles //
.pc-mp-header {
  position: fixed;
  width: 100%;
  z-index: 10000;
  align-items: center;
  display: flex;
  font-size: 18px;
  margin: auto;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  .pc-mp-header--container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .desktop-logo.black-logo {
    display: none;
  }

  .split {
    width: 40% !important;
  }

  .logo {
    margin: 0;

    a {
      display: flex;
    }
  }

  .main-nav-links {
    justify-content: flex-end;
    height: 100%;
    align-items: center;
    font-size: 16px;
    display: flex;

    .nav-cta {
      padding: 5px;
      border: 1px solid $white;
      border-radius: 3px;
      transition: all 0.3s ease;
    }

    .nav-cta:hover {
      background: $white;
      color: $orange !important;
    }

    a {
      color: white;
      padding: 5px 0;
      margin-right: 12px;
      font-size: 0.99rem;
      height: 100%;
      align-items: center;
      display: flex;
    }

     a:last-child {
      margin-right: 0;
     }

    a:hover, a.active {
      box-shadow: inset 0 -4px 0 #f47e42;
    }
  }

  .pc-mp-header--menu-button-container {
    position: absolute;
    right: 0;
    padding: 13px 15px;

    .pc-mp-mobile-menu-button {
      display: flex;
      color: $white;
      align-items: center;
      cursor: pointer;

      .fa-bars {
        padding-right: 5px;
      }
    }
  }

  .pc-mp-header--menu-sections-container {
    width: 0;
    opacity: 0;
    background: white;
    right: 0;
    position: absolute;
    top: 50px;
    border-top: 1px solid #ddd;
    height: 100vh;
    padding-bottom: 150px;
    z-index: 1000;
    overflow: scroll;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    .pc-mp-mobile-menu-section {
      .pc-mp-mobile-menu-section-title {
        margin: 15px auto;
        font-size: 20px;
      }

      .pc-mp-mobile-menu-link {
        margin: 25px auto;

        a {
          padding: 5px;
          border: 1px solid;
        }
      }

      .pc-mp-mobile-menu-link.active {
        a {
          color: $procore;
        }
      }
    }
  }

  .pc-mp-header--menu-sections-container.opened {
    width: 100%;
    opacity: 1;
  }
}

.pc-mp-app-grid-section {
  #listings-index {
    width: 100%;
  }
}
// Modal //
#submit-idea {

  .submit-idea-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .submit-idea-explain {
    h2 {
      font-size: 26px;
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .submit-idea-illustration {
    min-width: 200px;
    max-width: 350px;
    padding-top: 25px;
  }
}

// Footer Styles //
footer { height:45px; width:auto; clear:both; background-color:#f0f0f0; font-size:13px; color:#888; line-height:1.2; font-weight:400; }
.footer-legal { background-color:#f0f0f0; font-size:11px; padding-top:15px; text-align:center; color:#aaa; }
.footer-legal a { color:#aaa; }
.footer-legal a:hover { color:#f58025; }

// Media Queries //
@media (min-width: 1280px) {
  .pc-mp-header {
    padding: 0 50px;
    height: 75px;
  }

  #procore-logo {
    height: 20px;
  }

  .pc-mp-header--menu-button-container {
    top: 10px;
  }

  .pc-mp-header--pc-mp-large-header {
    display: flex;
    font-size: 0.6rem;
  }

  .pc-mp-header {
    background: transparent;
  }

  .solid-header {
    background: #1d3243 !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

@media (max-width: 1280px) {
  .logo {
    margin-left: 25px;
  }

  .pc-mp-header {
    height: 70px;
    padding: 0 15px;


    .main-nav-links > a {
      font-size: 13px;
    }
  }

  #procore-logo {
    height: 15px;
    margin-top: 5px;
  }

  .pc-mp-header--menu-button-container {
    top: 0;
  }

  .pc-mp-app-grid-section {
    justify-content: center;

  }

  .pc-mp-header {
    background: #1d3243 !important;;
  }
}

@media (min-width: 1001px) {
  .mobile-menu-container, .mobile-logo {
    display: none;
  }

  #submit-idea {
    width: 60%;
  }
}

@media (max-width: 1000px) {
  .main-nav-links, .header-search-container {
    display: none !important;
  }

  .mobile-menu-container, .mobile-logo {
    display: flex;
  }

  .pc-mp-header {
    height: 50px;
  }

  #submit-idea {
    width: 95%;
  }
}

@media (max-width: 512px) {
  .modal {
    width: 100%;
  }
  .blocker:before {
    height: 0% !important;
  }
}

@media (max-width: 350px) {
  #procore-logo {
    height: 20px;
  }
}

.display-white-space {
  white-space: pre-wrap;
}