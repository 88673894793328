.company-select-form {
  .button-continue {
    width: 100%;
  }
}

.select-company-top {
  padding: $site-spacing-unit $site-spacing-unit 0 $site-spacing-unit;
}

.select-company-bottom {
  padding: $site-spacing-unit;
  color: $gray;

  #company-selector {
    min-width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .company-picker {
      width: 100%;
    }

    .company-select-dropdown {
      min-width: 308px;
    }
  }
}
