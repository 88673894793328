.pc-mp-hero {
  background-position: top center;
  background-image: url("/images/marketplace_header.png");
  background-size: cover;
  font-weight: $font-weight-light;
  width: 100%;
  margin: auto;
  padding-top: 110px;


  .pc-mp-hero-text-container {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    font-weight: 500;
    color: white;
    font-size: 22px;

    h1 {
      font-weight: 700;
    }

  }

  .hero-top-text, .animated:hover {
    color: white !important;
    font-weight: $font-weight-bold;
  }

  .hero-top-text-container {
    display: flex;

    .hero-top-text {
      font-weight: 700;
      color: #434343;
    }

    .rw {
      #js-rotating {
        .rw-text {
          a {
            color: white !important;
          }
        }
      }
    }

    .procore-plus-text {
      margin: 0 0 0 auto;
    }

    .rw {
      margin: 0 auto 0 8px;
      cursor: pointer;
    }

    #js-rotating .animated:hover {
      color: #f38034;
    }

    #js-rotating span {
      text-decoration: underline;
    }
  }

  h2 {
    text-align: center;
    margin: 0;
    padding: 0.5rem 0 1rem;
    font-weight: 400;
    color: white;
  }
}

@media (min-width: 1250px) {
  .pc-mp-hero {
    background-attachment: fixed;
  }

}

@media (min-width: 769px) {
  .pc-mp-hero {
    height: 400px;
  }

  .hero-description {
    margin: 75px auto 0;

    .hero-slideshow-logo-container {
      min-width: 150px;
      max-width: 150px;
      height: 150px;

      .hero-slideshow-logo {
        width: 100px;
      }
    }
  }

  .hero-top-text {
    padding-top: 28px;
    font-size: 2.7rem;
  }

  .hero {
    height: 140px;

    p {
      margin: 10px auto;
    }
  }
}

@media (max-width: 769px) {
  .pc-mp-hero {
    height: 300px;

    h2 {
      font-size: 14px;
    }
  }

  .hero-description {
    margin: 25px auto 0;
    text-align: center;
    flex-wrap: wrap;

    .hero-slideshow-logo-container {
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      margin: 0 auto 15px;

      .hero-slideshow-logo {
        width: 30px;
      }
    }
  }

  .hero-top-text {
    padding-top: 10px;
    font-size: 25px;
  }

  .hero {
    height: 85px;

    p {
      margin: 5px auto;
    }
  }
}
