.pc-mp-body--faq-body {

  .pc-mp-faqs-container {
    margin: 0 10px;

    .pc-mp-faq-container {
      background: $white;
      border-bottom: 1px solid #ddd;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      padding: 25px;
      margin-bottom: 15px;

      h5 {
        font-size: 18px;
        margin: 0;
      }

      p {
        font-size: 16px;
        margin-bottom: 15px;
        line-height: 22px;
        font-weight: 300;
        height: auto;
        padding-bottom: 10px;
      }

      a {
        text-decoration: underline !important;
      }
    }
  }
}

@media (min-width: 1250px) {
  .pc-mp-body--faq-body {
    padding: 50px 25px;
  }
}

@media (max-width: 1250px) {
  .pc-mp-body--faq-body {
    padding: 25px;
  }
}
