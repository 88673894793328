html {
  height: 100%;
}

.sign-in-layout {
  background: url("/images/marketplace_header.png");
  background-size: cover;
  height: 100%;
  min-height: 100%;
}

.sign-in, .company-select {
  height: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.signin-form, .company-select-form {
  background-color: #fff;
  border-radius: $site-border-radius;
  border-radius: 5px;
  text-align: center;

  h2 {
    font-weight: 400;
    letter-spacing: 2px;
    margin-bottom: $site-spacing-unit;
  }

  .signin-top {
    padding: $site-spacing-unit;
  }

  .signin-bottom {
    border-top: 1px solid #DFDFE0;
    padding: $site-spacing-unit;
    color: $gray;
  }
}

.sign-in__extra-links {
  margin-top: calc($site-spacing-unit / 2);

  a {
    color: white;
    font-size: 12px;
    text-decoration: underline;
  }
}
