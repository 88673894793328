.pc-mp-work-together {
  background-position: center center;
  background-image: url("/images/lets-work-together.jpg");
  background-size: cover;
  height: 600px;
  text-align: center;

  h1 {
    color: #fff;
    margin: 0;
    padding: 164px 0 10px 0;
    text-align: center;
  }

  p {
    color: $white;
    font-size: 18px;
    line-height: 26px;
    margin: 0 auto;
    padding: 0 5px;
    text-align: center;
    margin-bottom: 45px;
  }

  a {
    color: $procore;
  }
}
