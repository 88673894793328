.app-show {
  max-width: $site-content-width;
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 45px);

  hr {
    background: #c2c2c2;
    border: none;
    height: 1px;
    margin: 25px 0;
  }
}

.app-categories {
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $border-color;
  height: 60px;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 1000;

  a {
    display: block;
    font-size: 14px;
    height: 58px;
    line-height: 58px;
    padding: 0 16px;
    background-color: #fff;

    &.active {
      border-bottom: 3px solid $procore;
    }
  }
}

.pc-mp-app-grid-section {
  display: flex;
  flex-wrap: wrap;
  margin: 50px auto;
  max-width: $site-content-width;
}

.app-interest-form {
  width: 100%;
  max-width: $site-content-width;
  flex: 0 0 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  h1 {
    flex: 0 0 100%;
  }

  .app-top-description {
    display: flex;
    position: relative;
    margin-bottom: 25px;

    .app-logo-box {
      margin-right: 15px;

      .app-logo {

        .app-logo-container {
          max-width: 100%;
          margin: 0 auto;
          background: #fff;
          display: inline-block;
          border: 1px solid #c2c2c2;
          margin-right: 1rem;
          height: 75px;
          line-height: 2.5rem;
          min-width: 75px;
          text-align: center;
          width: 75px;
          justify-content: center;

          .app-logo-container-position {
            height: 75px;
            width: 75px;
            position: relative;

            img {
              padding: 10px;
              max-height: 100%;
              max-width: 100%;
              object-fit: contain;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }

  .built_by {
    margin: 5px 0;
    font-size: 15px;

    .built-by-link, .built-by-link a {
      color: $procore !important;
    }
  }

  .description {
    font-size: 16px;
    line-height: 22px;

    h1 {
      margin: 0;
    }

    .fa-check {
      color: $procore;
      font-size: 1.5em;
    }

    img {
      max-width: 100%;
    }
  }

  .app-full-description {
    h4 {
      font-size: 22px;
      margin: 0;
      font-weight: 700;
      padding-top: 5px;
      line-height: 26px;
    }

    h5 {
      font-size: 20px;
      margin: 0 auto;
    }

    .app-bullet-point {
      display: flex;

      .fa {
        font-size: 16px;
        padding-top: 10px;
      }

      h4 {
        padding-left: 10px;
        font-weight: 400;
      }
    }
  }

  .form {
    display: flex;
    flex-wrap: wrap;

    .left {
      width: 50%;
      padding-right: 12px;
    }

    .right {
      width: 50%;
      padding-right: 12px;
    }
  }

  .screenshots-on-top {
    width: 100%;
    margin: 0 auto 75px;

    .screenshots-slider-container {
      position: relative;

      .screenshot-slider-control-left, .screenshot-slider-control-right {
        display: flex !important;
        position: absolute;
        top: calc(50% - 50px);
        cursor: pointer;
        width: 50px;
        height: 75px;
        transition: all .2s ease-in-out;
        padding: 15px;
        background: #1d3243;
      }

      .screenshot-slider-control-left:hover, .screenshot-slider-control-right:hover {
        transform: scale(1.1);
      }

      .screenshot-slider-control-left {
        right: -15px;
      }

      .screenshot-slider-control-right {
        left: -15px;
      }
    }

    .screenshot-container a img {
      margin: 0 auto;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
    }
  }
}

.app-interest-form .fill-in-side {
  .app-pricing-box, .app-form-box, .app-support-box {
    max-width: 500px;
    width: 100%;
  }

  .app-support-box {
    background: white;
    -moz-box-shadow: rgba(0, 0, 0, 0.125) 0 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.125) 0 1px 3px;
    box-shadow: rgba(0, 0, 0, 0.125) 0 1px 3px;
    padding: 25px;
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;

    h6, p {
      width: 100%;
    }

    h6 {
      margin: 0;
      font-size: 18px;
      padding-bottom: 10px;
    }

    p {
      overflow: hidden;
    }

    .underline-p {
      border-bottom: 1px solid #f0f0f0;
      padding: 0 0 10px;
    }

    .app-support-box-right {
      float: right;
      text-align: right;
      overflow: hidden;
      max-width: 75%;
      text-overflow: ellipsis;

      a {
        color: $orange;
      }

      a:hover {
        color: $font-color-base;
      }
    }
  }

  .app-pricing-box {
    padding: 25px;
    display: flex;
    flex-direction: column;

    h6 {
      margin: 0;
      font-size: 18px;
    }

    .app-pricing-box-top {
      display: flex;
      flex: 1;
      align-items: center;
    }

    .app-pricing-box-bottom {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .primaryButton {
        margin-top: 0;
      }

      form, a.primaryButton {
        width: 49%;
      }

      p {
        margin: 0;
        padding-top: 2.5px;
      }

      #install-button-container, .install-button-container {
        width: 49%;

        #non-admin-install-popover {
          float: right;
        }
        .primaryButton {
          width: 100%;
        }
      }
    }
  }

  #screenshots {
    background: white;
    -moz-box-shadow: rgba(0, 0, 0, 0.125) 0 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.125) 0 1px 3px;
    box-shadow: rgba(0, 0, 0, 0.125) 0 1px 3px;
    padding: 25px;
    margin-top: 25px;

    h6 {
      margin: 0;
      font-size: 18px;
    }

    .screenshot-thumbnails {
      display: flex;
      flex-wrap: wrap;
    }

    a {
      margin: 5px;
    }

    .screenshot {
      max-height: 125px;
      max-width: 125px;
      cursor: pointer;
    }
  }

  .app-form-box {
    background: white;
    -moz-box-shadow: rgba(0, 0, 0, 0.125) 0 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.125) 0 1px 3px;
    box-shadow: rgba(0, 0, 0, 0.125) 0 1px 3px;
    padding: 25px;
    margin-top: 25px;
    display: flex;
    align-items: center;

    h6 {
      margin: 0;
      width: 100%;
      font-size: 18px;
    }

    .app-form-button {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

#screenshots-top {
  background: white;
  border: 1px solid #d8d8d8;
  padding: 15px;
  margin-top: 25px;

  h6 {
    margin: 0 0 15px;
    font-size: 18px;
  }

  .screenshot-thumbnails {
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
  }

  a {
    margin: 5px;
  }

  .screenshot {
    max-height: 150px;
    max-width: 150px;
    cursor: pointer;
  }

  .individual-screenshot {
  }
}

.screenshot-modal {
  width: 95%;
  height: 95%;

  .screenshot-modal-content {
    background-color: #F0F0F0;
    display: flex;
    max-height: 100%;
    justify-content: center;

    #screenshot-in-modal-wrapper {
      display: inline-block;
      max-width: 100%;
      text-align: center;

      img {
        width: auto;
        width: initial;
        max-width: 100%;
        display: block;
        height: 100%;
        margin: auto;
      }
    }
  }
}

#contact-form, #question-form {
  p {
    padding-bottom: 15px;
  }
}

@media (min-width: 1250px) {
  .app-grid-container {
    width: 80%;
  }

  .app-grid {
    justify-content: flex-start;
  }

  .app-show {
    padding: 100px 0;
  }
}

@media (max-width: 1250px) {
  .app-grid-container {
    width: 100%;
  }

  .app-grid {
    justify-content: center !important;
  }

  .app-breadcrumbs-container {
    padding: 0 25px;
  }

  .app-show {
    padding: 75px 0;
  }

  .app-interest-form {
    .description, .fill-in-side {
      padding: 12px 25px;
      width: 100%;
    }
  }

  .app_filter_text_container {
    text-align: center;
  }
}

@media (min-width: 1130px) {
  .app-categories {
    display: flex;
  }

  .vertical-menu-separator {
    background: #3d3d3d;
    width: 1px;
    height: 35px;
    margin-top: 10px;
  }

  .app-interest-form {
    h1 {
      line-height: 40px;
      font-size: 40px;
    }

    .description {
      width: 66%;
    }

    .fill-in-side {
      padding-left: 25px;
      width: 34%;
    }
  }
}

@media (max-width: 1130px) {
  .app-categories {
    display: block;

    a {
      border-bottom: 1px solid #ddd;
    }
  }

  #mobile-category-menu {
    display: flex;
    justify-content: center;
    font-size: 20px;
    vertical-align: middle;
    padding-top: 17px;
    cursor: pointer;

    .fa-bars {
      padding: 3px;
    }
  }

  #category-list, .vertical-menu-separator {
    display: none;
  }

  .app-interest-form {
    h1 {
      line-height: 30px;
      font-size: 30px;
    }
  }
}

@media (min-width: 769px) {
  .screenshot-slider-control-left, .screenshot-slider-control-right {
    opacity: 0;
  }
}

@media (max-width: 769px) {
  .screenshot-slider-control-left, .screenshot-slider-control-right {
    opacity: 0.85 !important;
  }
}

#product-tools {
  border-collapse: collapse;
  width: 100%;
  margin-top: 30px;

  th {
    border: 1px solid #e3e4e6;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    padding: 10px;
  }

  td {
    border: 1px solid #e3e4e6;
    padding: 10px;
  }

  tr:nth-child(odd) {
    background-color: #fff;
  }

  tr:nth-child(even) {
    background-color: #f4f5f6;
  }

  td:nth-child(2) {
    text-align: center;
  }

  svg:nth-child(2) {
    margin-left: -10px;
  }

  + hr {
    border: 1px solid #e3e4e6;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

