.searchWrapper {
  margin: 0 auto;
  position: relative;

  button {
    top: 14px;
  }
}

.searchInput {
  height: 50px !important;
}

.searchForm, .searchResultsWrapper {
  width: 100%;
}

.searchResultsWrapper {
  position: absolute;
  left: 0;
  top: 100%;
}

.searchResultsHidden {
  display: none;
}

.searchResultsVisible {
  display: block;
  z-index: 9999;
}

.searchResultsMenu {
  max-height: 624px !important;
}

.searchResultsMenuOptions {
  padding: 0 !important;
}

.searchResultsItem {
  background-color: #fff !important;
  border-bottom: 1px solid #d3d3d3;

  &:hover {
    background-color: hsl(200,8%,96%) !important;
  }
}

.searchResultsItemInner {
  padding-top: 15px;
  padding-bottom: 15px;
}

.searchResultsThumbnail {
  width: 35px;
  max-height: 35px;
}

.searchResultsText {
  text-align: center;
}
