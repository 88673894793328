.avatarCircle {
  position: relative;
  background-color: #777;
  border-radius: 50%;
  height: 35px;
  min-height: 35px;
  width: 35px;
  min-width: 35px;

  .userIcon {
    content: '';
    background-image: url('./person-light.svg');
    width: 24px;
    height: 24px;
    display: inline-block;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    border-bottom-right-radius: 14px 9px;
    border-bottom-left-radius: 14px 9px;
  }
}

.dropdownContainer {
  .email {
    text-align: center;
    padding: 5px 10px;
    font-size: 14px;
    color: #282828;
  }

  .company {
    text-align: center;
    padding: 5px 10px;
    font-size: 14px;
    color: #282828;
  }

  .footer {
    border-top: 1px solid #DFDFE0;
    font-size: 14px;
    text-align: center;
  }
}
