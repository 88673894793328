@use "sass:color";

body {
  .btn {
    border-radius: 4px;
    border: none;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;

    &.btn-outline {
      padding: 15px 30px;
      border-radius: 40px;
      border: 2px solid $border-color;
      font-size: 16px;
      font-weight: 600;
    }

    &.btn-outline--small {
      padding: 10px 25px;
      border-radius: 40px;
      border: 2px solid $border-color;
      font-size: 13px;
      font-weight: 400;
    }

    &.white{
      color: $white;
      border-color: $white;
    }

    &.white:hover{
      background-color: $white;
      color: $procore;
      box-shadow: 0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.20);
    }

    &.btn-large {
      background-color: $procore;
      color: $white;
      font-size: 14px;
      font-weight: $font-weight-bold;
      padding: 16px 24px;
      transition: background-color, 0.4s;

      &:hover {
        background-color: color.adjust($procore, $lightness: -10%);
      }

      &.green {
        background-color: $green;

        &:hover {
          background-color: color.adjust($green, $lightness: -5%);
        }
      }
    }

    &.extra-large {
      font-size: 20px;
    }
  }

  .coreButton {
    font-size: 14px;
    letter-spacing: 0.15px;
    line-height: 20px;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    display: inline-flex;
    font-weight: 600;
    height: 36px;
    overflow: hidden;
    padding: 0px 12px;
    border: 0;

    &.primary {
      background: rgb(255, 81, 0);
      color: white;
    }

    &.secondary {
      background: #e3e6e8;
      color: #232729;
    }
  }

  .primaryButton, .mktoButton {
    background: linear-gradient(135deg, #F47E42, #FB7272);
    color: #fff !important;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    border-radius: 40px;
    margin-top: 16px;
    padding: 15px 25px;
    border: 0;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;

    &:hover:not(.disabled) {
      cursor: pointer;
      box-shadow: 0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.20);
    }

    &.disabled {
      cursor: default;
      background: lightgray;
    }

    &.primaryButton__small {
      padding: 10px 20px !important;
      margin: 0 !important;
    }

    &.primaryButton__xsmall {
      padding: 2px 10px !important;
      margin: 0 !important;
      font-size: 12px;
    }
  }
}
