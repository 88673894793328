$z-popover: 1000;

.popoverWrapper {
  position: relative;
  filter: drop-shadow(0px 3px 2px rgba(0,0,0,0.23)) drop-shadow(0px 3px 6px rgba(0,0,0,0.16));
  width: fit-content;
}

.popoverContent {
  z-index: 1000;
  position: absolute;
  padding: 16px;
  border-radius: 2px;
  background-color: white;
  transform: translateX(-50%);
  min-width: 220px;
  text-align: center;
}

.popoverWrapper:hover {
  .popoverContent {
    display: block;
  }
}

.popoverTarget {
  color: #777;
  width: fit-content;
}

.topPopoverContent {
  display: none;
  bottom: 32px;
  left: 5px;

  &:after {
    position: absolute;
    z-index: $z-popover;
    content: "";
    right: calc(50% - 10px);
    bottom: -8px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: white transparent transparent transparent;
  }
}

.bottomPopoverContent {
  display: none;
  top: 32px;
  left: 5px;

  &:before {
    position: absolute;
    z-index: $z-popover;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent white transparent;
  }
}
