a {
  text-decoration: none !important;
}

body {
  width: 100%;
}

// Base Colors
$white: #fff;
$black: #000;
$blue: #2180d7;
$green: #1DB10A;
$gray: #777;
$lightgray: #ABACAE;
$orange: #F47E42;
$red:    #c52f24;
$procore: $orange;

// Site Colors
$background-color: #f0f0f0;
$border-color: #ddd;
$muted: $gray;
$notice: #e8b809;

// Fonts
$font-base-size: 14px;
$font-color-base: #2d3747;
$font-family-base: "Open Sans", sans-serif;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

// Site
$site-content-width: 1200px;
$admin-content-width: 820px;
$site-border-radius: 2px;
$site-spacing-unit: 24px;
$header-height: 50px;

@import "base/normalize";

@import "base/buttons";
@import "base/forms";
@import "base/layout";
@import "base/tables";
@import "base/typography";

@import "components/app-grid";
@import "components/hero";
@import "components/dropdown";
@import "components/search-input";

@import "pages/app-store-announcement";
@import "pages/build";
@import "pages/faqs";
@import "pages/work-together";
@import "pages/sign-in";
@import "pages/select-company";

@import "vendor/animate";
@import "vendor/jquery.modal";
@import "vendor/slick";
@import "vendor/slick-theme";

