$z-dropdown: 1000;

.dropdown {
  position: relative;

  .dropdown-trigger {
    cursor: pointer;
  }

  .dropdown-menu-position {
    display: none;
    position: absolute;
    z-index: $z-dropdown;
    min-width: 220px;
  }

  &.dropdown--open {
    .dropdown-menu-position {
      display: block;
    }
  }

  &.dropdown--drop-west {
    .dropdown-menu-position {
      right: 0;
    }
  }

  &.dropdown--drop-east {
    .dropdown-menu-position {
      left: 100%;
      margin-left: 20px;
      bottom: -31px;
    }
  }

  &.dropdown--drop-south {
    display: block;
  }

  .dropdown-menu-style {
    text-transform: none;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: rgba(84, 70, 35, 0.298039) 0px 6px 20px, rgba(84, 70, 35, 0.15) 0px 1px 3px, rgba(0, 0, 0, 0.08) 0px 0px 1px;
    overflow-y: scroll;
    max-height: 300px;
    width: 100%;
  }

  .dropdown-default-target {
    display: flex;
    padding: 8px;
    background-color: $white;
    border: 1px solid $lightgray;
    border-radius: 2px;
    max-width: 220px;
    justify-content: space-between;
  }

  .dropdown-item {
    display: block;
    padding: 8px;
    background-color: $white;

    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }
}
