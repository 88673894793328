.pc-mp-app-store-announcement {
  background: linear-gradient(135deg, #F47E42, #FB7272);
  overflow: hidden;
  padding: 32px 0;
  width: 100%;

  h1 {
    color: $white;
    padding: 32px 0;
    text-align: center;
  }

  .growing-icons {
    display: block;
    overflow: hidden;
    text-align: center;

    img {
      width: 1200px;
      padding-left: 10px;
    }
  }

  .submit-idea {
    color: $white;
    font-size: 16px;
    margin: 40px 0 64px;
    text-align: center;

    p {
      margin-bottom: 45px;
      font-size: 18px;
    }
  }
}
