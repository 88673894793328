.buttonContainer {
  width: 100%;
}

.button {
  width: 100% !important;
}

.modalFooter {
  width: 100%;
}

.footerButtons {
  float: right
}

.footerButton {
  margin: 5px;
}

.notesInput {
  width: 100%;
  min-width: 300px;
  border: none;
}

.popover {
  float: right;
}

.requestModal {
  min-width: fit-content;
  max-width: 600px;
  width: inherit;
}
